html,
body {
  background-color: #fafafa;
}
@media print {
  html,
  body {
    background-color: white !important;
  }
}

/* override bootstrap form validation styles */
.was-validated .form-control:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.was-validated .form-control:valid {
  border: 1px solid #ced4da !important;
  background-image: none !important;
}

/* select box */
.was-validated .form-select:valid {
  border: 1px solid #ced4da !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
}

.was-validated .form-select:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

/* Checkbox */
.was-validated .form-check-input:valid ~ .form-check-label {
  color: inherit;
}
.was-validated .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
}

.was-validated .form-check-input:valid {
  border: 1px solid #ced4da !important;
  background-color: normal !important;
}

.was-validated .form-check-input:valid:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
